import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import axios from 'axios'
import getConfig from '../utils/get-config'
import Layout from '../components/layout'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faThumbsUp, faInfoCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha'

const DevenirMembre = (props) => {
    const [dogs, setDogs] = useState([
        {
            index: 1,
            dogName: '',
            dogbirthdate: '',
            dogBreed: '',
            dogId: ''
        },
        {
            index: 2,
            dogName: '',
            dogbirthdate: '',
            dogBreed: '',
            dogId: ''
        },
        {
            index: 3,
            dogName: '',
            dogbirthdate: '',
            dogBreed: '',
            dogId: ''
        }
    ])
    const [dogBreeds, setDogBreeds] = useState([])
    const [isOtherBreedVisible, setIsOtherBreedVisible] = useState([])
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, touchedFields, submitCount }
    } = useForm()
    let numberOfDogs = 1
    const onSubmit = (data) => {
        let registerForm = document.getElementById('register-form')
        let registerFormError = registerForm.querySelector('.error-tip')
        let registerFormIsValid = true
        registerForm.querySelectorAll('input[type="text"], input[type="email"], select').forEach((formField) => {
            if (!formField.getAttribute('name').startsWith('dog')) {
                formField.classList.toggle('error', formField.value === '')
                formField.parentNode.querySelector('.error-icon').classList.toggle('show', formField.value === '')

                if (formField.value === '')
                    registerFormIsValid = false
            }
        })

        let optinCheckbox = registerForm.querySelector('input[name="optin"]')
        optinCheckbox.classList.toggle('error', !optinCheckbox.checked)
        optinCheckbox.parentNode.querySelector('.error-icon').classList.toggle('show', !optinCheckbox.checked)
        if (!optinCheckbox.checked)
            registerFormIsValid = false

        registerFormError.classList.toggle('show', !registerFormIsValid)

        let captchaField = document.getElementById('captcha')
        let captchaFieldIsValid = validateCaptcha(captchaField.value, false)
        captchaField.parentNode.querySelector('.error-icon').classList.toggle('show', !captchaFieldIsValid)
        captchaField.classList.toggle('error', !captchaFieldIsValid)

        if (registerFormIsValid && captchaFieldIsValid) {
            axios.post(`${getConfig('webServciesUrl')}register-owner.php`, data).then(response => {
                let registerSuccess = document.getElementById('register-success')

                if (response.data[0] === 'success') {
                    registerForm.querySelectorAll('input').forEach((input) => input.value = '')
                    registerForm.querySelectorAll('select').forEach((select) => select.selectedIndex = 0)

                    registerForm.classList.add('hide')
                    registerSuccess.classList.add('show')

                    registerFormError.classList.remove('show')
                } else
                    registerFormError.classList.add('show')
            }).catch(function (error) {
                console.log(error)
            })
        }
    }
    
    const addDog = () => {
        numberOfDogs++
        let dogsNumberInput = document.querySelector('input[type="hidden"][name="dogs-number"]')
        //dogsNumberInput.value = numberOfDogs
        for (let i = 1; i <= numberOfDogs; i++)
            document.querySelector(`.form-container.dog[data-index="${i}"]`).classList.add('show') 
        document.getElementById('add-dog-button').classList.toggle('hidden', numberOfDogs >= 3)
    }

    const getDogBreeds = () => {
        axios.get(`${getConfig('webServciesUrl')}get-dogbreeds.php`).then(response => {
            const dogBreeds = response.data

            setDogBreeds(dogBreeds)
        }).catch(function (error) {
            console.log(error)
        })
    }

    useEffect(() => {
        getDogBreeds()

        document.querySelectorAll('select[name^="dogbreed-"]').forEach((select) => {
            select.addEventListener('change', function() {
                let otherBreedInput = select.closest('.form-left').querySelector('.dogbreed-other-container')
                if (select.value != 'Other')
                    otherBreedInput.querySelector('input[type="text"][name^="dogbreed-other-"]').value = ''
                otherBreedInput.classList.toggle('visible', select.value == 'Other')
            })
        })

        let registerForm = document.getElementById('register-form')
        let registerConfirmed = document.getElementById('register-confirmed')
        let registerProblem = document.getElementById('register-confirmed')
        
        let locationHrefParams = props.location.href
        if (locationHrefParams.includes('?')) {
            locationHrefParams = locationHrefParams.split('?')[1]
            if (locationHrefParams.length >= 2) {
                let params = []
                let locationHrefParamsArray = []
                if (locationHrefParams.includes('&'))
                    locationHrefParamsArray = locationHrefParams[1].split('&')
                else
                    locationHrefParamsArray.push(locationHrefParams)
                locationHrefParamsArray.forEach((param) => {
                    params.push({
                        'name':  param.split('=')[0],
                        'value':  param.split('=')[1]
                    })
                })
                params.forEach((param) => {
                    if (param.name == 'registration-confirmed') {
                        if (param.value == 'true') {
                            registerForm.classList.add('hide')
                            registerConfirmed.classList.add('show')
                        } else if (param.value == 'false') {
                            registerForm.classList.add('hide')
                            registerProblem.classList.add('show')
                        }
                    }
                })
            }
        }

        loadCaptchaEnginge(6,'#f2a13f','#000')
    }, [])

    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Devenir membre du centre</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <section id="become-member">
                    <h1 className="page-title fredoka">
                        Devenir membre de notre centre
                    </h1>
                    <div className="grid">
                        <section id="register-form" className="block">
                            <h2 className="block-title balsamiq">
                                Vous recevrez une confirmation ainsi que les étapes suivantes de l'inscription par email après avoir rempli le formulaire avec vos informations et validé celui-ci.
                            </h2>
                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                <span className="error-tip">
                                    <FontAwesomeIcon icon={faExclamationCircle} className="icon" />
                                    <strong>
                                        Une ou plusieurs informations sont incorrectes ou incomplètes ou cette adresse email est déjà enregistrée.
                                    </strong>
                                    Veuillez vérifier que vous avez rempli tous les champs du formulaires, informations relatives à votre chien y-comprises.
                                </span>
                                <h3 className="form-title balsamiq">
                                    Coordonnées du propriétaire :
                                </h3>
                                <div className="form-container">
                                    <div className="form-left">
                                        <div className="form-line">
                                            <div className="label-container">
                                                <label htmlFor="firstname">
                                                    Prénom :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="firstname" name="firstname" autoComplete="off" {...register('firstname')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="lastname">
                                                    Nom :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="lastname" name="lastname" autoComplete="off" {...register('lastname')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="street">
                                                    Nom de rue :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="street" name="street" autoComplete="off" {...register('street')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="street_number">
                                                    N° de rue :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="street_number" name="street_number" autoComplete="off" {...register('street_number')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="zipcode">
                                                    Code postal :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="zipcode" name="zipcode" autoComplete="off" {...register('zipcode')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-right">
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="city">
                                                    Localité :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="city" name="city" autoComplete="off" {...register('city')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="country">
                                                    Pays :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <select id="country" name="country" {...register('country')}>
                                                    <option value="belgium" defaultValue>Belgique</option>
                                                    <option value="luxembourg">Luxembourg</option>
                                                    <option value="france">France</option>
                                                    <option value="netherlands">Pays-Bas</option>
                                                </select>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="email">
                                                    Adresse email :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="email" id="email" name="email" autoComplete="off" {...register('email')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="phone">
                                                    Téléphone :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="phone" name="phone" autoComplete="off" {...register('phone')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                        <div className="form-line">     
                                            <div className="label-container">
                                                <label htmlFor="insurance_policy">
                                                    N° assurance RC familiale :
                                                </label>
                                            </div>
                                            <div className="field-container">
                                                <input type="text" id="insurance_policy" name="insurance_policy" autoComplete="off" {...register('insurance_policy')} />
                                                <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="form-title balsamiq">
                                    Votre/vos chien(s) :
                                </h3>
                                {/* <input type="hidden" name="dogs-number" value={numberOfDogs} {...register('dogs-number')} /> */}
                                {dogs.map((dog) => {
                                    return (
                                        <div className="form-container dog" data-index={dog.index} key={dog.index}>
                                            <div className="form-left">
                                                <div className="form-line">
                                                    <div className="label-container">
                                                        <label htmlFor={`dogname-${dog.index}`}>
                                                            Nom :
                                                        </label>
                                                    </div>
                                                    <div className="field-container">
                                                        <input type="text" id={`dogname-${dog.index}`} name={`dogname-${dog.index}`} autoComplete="off" {...register(`dogname-${dog.index}`)} />
                                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                                    </div>
                                                </div>
                                                <div className="form-line">
                                                    <div className="label-container">
                                                        <label htmlFor={`dogbreed-${dog.index}`}>
                                                            Race :
                                                        </label>
                                                    </div>
                                                    <div className="field-container">
                                                        <select id={`dogbreed-${dog.index}`} name={`dogbreed-${dog.index}`} {...register(`dogbreed-${dog.index}`)}>
                                                            <option value="">Choisissez une race</option>
                                                            {dogBreeds.map((breed, index) => {
                                                                return <option value={breed} key={index}>{breed}</option>
                                                            })}
                                                            <option value="Other">Autre</option>
                                                        </select>
                                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                                    </div>
                                                </div>
                                                <div className="form-line dogbreed-other-container">
                                                    <div className="label-container">
                                                        <label htmlFor={`dogbreed-other-${dog.index}`}>
                                                            Autre race :
                                                        </label>
                                                    </div>
                                                    <div className="field-container">
                                                        <input type="text" id={`dogbreed-other-${dog.index}`} name={`dogbreed-other-${dog.index}`} autoComplete="off" {...register(`dogbreed-other-${dog.index}`)} />
                                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-right">
                                                <div className="form-line">     
                                                    <div className="label-container">
                                                        <label htmlFor={`dogbirthdate-${dog.index}`}>
                                                            Date de naissance :
                                                        </label>
                                                    </div>
                                                    <div className="field-container">
                                                        <input type="text" id={`dogbirthdate-${dog.index}`} name={`dogbirthdate-${dog.index}`} autoComplete="off" placeholder="01/01/2021" {...register(`dogbirthdate-${dog.index}`)} />
                                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                                    </div>
                                                </div>
                                                <div className="form-line">     
                                                    <div className="label-container">
                                                        <label htmlFor={`dogid-${dog.index}`}>
                                                            N° d'identification :
                                                        </label>
                                                    </div>
                                                    <div className="field-container">
                                                        <input type="text" id={`dogid-${dog.index}`} name={`dogid-${dog.index}`} autoComplete="off" {...register(`dogid-${dog.index}`)} />
                                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div id="add-dog-button" className="button primary" onClick={addDog}>
                                    Ajouter un chien
                                </div>
                                <span className="tip">
                                    <strong>
                                        Tous les champs sont obligatoires.
                                    </strong>
                                    En appuyant sur le bouton "Valider l'inscription" votre inscription n'est pas encore confirmée, vous recevrez un email sur l'adresse email renseignée dans lequel vous devrez cliquer sur le lien "Confirmer mon adresse email".
                                </span>
                                <div className="captcha-container">
                                    <label htmlFor="captcha">
                                        Recopiez les caractères :
                                    </label>
                                    <LoadCanvasTemplate reloadColor="#ee8a10" />
                                    <div className="field-container">
                                        <input type="text" id="captcha" name="captcha" autoComplete="off" {...register('captcha')} />
                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                    </div>
                                </div>
                                <div className="optin-container">
                                    <label htmlFor="optin">
                                        En cochant cette case vous acceptez notre <a href="/reglement-interieur" target="_blank">règlement intérieur</a>
                                    </label>
                                    <div className="field-container">
                                        <input type="checkbox" id="optin" name="optin" {...register('optin')} />
                                        <FontAwesomeIcon icon={faExclamationCircle} className="error-icon" />
                                    </div>
                                </div>
                                <button type="submit" className="button primary">
                                    Valider l'inscription
                                </button>
                            </form>
                        </section>
                        <section id="register-success" className="block">
                            <span className="title">
                                Votre inscription a été enregistrée mais n'est pas encore confirmée !
                            </span>
                            <span className="subtitle">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="icon" /> Vous avez reçu un email avec un lien pour confirmer celle-ci.
                            </span>
                            <span className="description">
                                Cela nous permettra de valider votre adresse email, laquelle sera utilisée pour toutes les communications futures relatives à votre inscription.<br /><br />
                                Vous allez également recevoir votre numéro de membre qui vous permettra de vous inscrire aux cours.<br /><br />
                                Si l'email n'est pas arrivé dans votre boite de réception, vérifiez dans votre dossier des courriers indésiréables (SPAM).<br /><br />
                                Vous pouvez nous contacter à l'adresse <a href="mailto:info@educadog.be;">info@educadog.be</a> pour obtenir de l'aide.
                            </span>
                        </section>
                        <section id="register-confirmed" className="block">
                            <span className="title">
                                Votre inscription est maintenant confirmée !
                            </span>
                            <span className="subtitle">
                                <FontAwesomeIcon icon={faThumbsUp} className="icon" /> Vous pouvez dès à présent vous inscrire aux cours grâce à votre numéro de membre qui vous a été envoyé par email.
                            </span>
                        </section>
                        <section id="register-problem" className="block">
                            <span className="title">
                                Ce lien de confirmation n'est pas ou plus valide !
                            </span>
                            <span className="subtitle">
                                <FontAwesomeIcon icon={faInfoCircle} className="icon" /> Vous pouvez nous contacter à l'adresse <a href="mailto:info@educadog.be;">info@educadog.be</a> pour obtenir de l'aide.
                            </span>
                        </section>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default DevenirMembre